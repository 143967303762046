
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-family: Quicksand;
  font-size: 18px;
}

.board {
  width: 90%;
  height: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
  background-color: antiquewhite;
  border: 2px solid #e9d3bf;
  border-radius: 10px;
}

.board > div {
  height: 42px;
  padding: 5px;
}

.divider1 {
  width: 98%;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #e9d3bf;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fullName {
  display: flex;
  align-items: center;
}

#iconUser {
  margin-right: 20px;
}

#iconInfo {
  margin-right: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info2 {
  display: flex;
}

#iconEmail {
  margin-right: 20px;
}

.email {
  display: flex;
  align-items: center;
}

#iconLock {
  margin-right: 20px;
}

.passWord {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.passWord2 {
  display: flex;
}

#iconPhone {
  margin-right: 20px;
}

.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phone2 {
  display: flex;
}

#iconLogout {
  margin-right: 20px;
}

.logout {
  display: flex;
  align-items: center;
}

#iconChevronRight {
  margin-right: 10px;
}

.passwordModal {
  width: 400px;
  height: 180px;
  border-radius: 10px;
  background-color: rgba(245, 171, 131,1);
  display: flex;
  align-items: center;
  /* opacity: 0.8; */
  padding: 10px;
  margin: 10px;
  justify-content: space-around;

  position: fixed;
  z-index: 999;
  color: black;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: flex;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
}
.modal-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;
}
.close {
  margin-right: 10px;
  border: 2px;
  border-radius: 5px;
  background-color: antiquewhite;
  padding: 4px;
  font-size: medium;
}
.submit {
  margin-right: 10px;
  border: 2px;
  border-radius: 5px;
  background-color: antiquewhite;
  padding: 4px;
  font-size: medium;
}
.phoneNumberModal {
  width: 400px;
  height: 160px;
  border-radius: 10px;
  background-color: rgba(245, 171, 131,1);
  display: flex;
  align-items: center;
  /* opacity: 0.8; */
  padding: 10px;
  margin: 10px;
  justify-content: space-around;

  position: fixed;
  z-index: 999;
  color: black;
}

.modal-content > label {
  width: 200px;
}

.modal-content > input {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #e9d3bf;
}
