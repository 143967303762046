

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 92vh;
    box-sizing: content-box;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  10px;
    margin: 10px;
    width: 90%;
    height: 30%;
    background-color: #c3b69f;
    border-bottom: 1px solid #e9ecef;
    box-sizing: border-box;
    overflow:hidden;
    
}

.carousel-item{
    margin:auto;
    height: 100%;
    width: 100%;
    box-sizing: content-box;
    overflow:auto;
    
}

.middleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  10px;
    margin: 10px;
    width: 90%;
    background-color: #c3b69f;
    border-bottom: 1px solid #e9ecef;
    height: 25%;
    }

.middleContainer img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    width: 90%;

}

.cardContainer {
    display: flex;
   flex-direction: row; 
    height: 100%;
    align-items: center;
    padding: 5px;
    background-color: #c3b69f;
    border-radius: 3px;    

}



.card1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 10px;
    width: 80%;
    height:100%;
    background-color: #efe2cd;
    border: 1px solid #c3b69f;
    border-radius: 10px;    
}

.card1 h1{
    margin: 10px;
    font-size: 18px;
    display: flex;
    text-align: justify;
    font-weight: bold;
    color: black;
}

.card1 p{
    margin: 10px;
    font-size: 15px;
    color: black;
}

.card2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    margin: 10px;
    width: 80%;
    height:100%;
    background-color: #efe2cd;
    border: 1px solid #c3b69f;
    border-radius: 10px;

}

.card2 h1{
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.card2 p{
    margin: 10px;
    font-size: 15px;
    color: black;
}


#Banner1{

    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;


}

.card1 img{
    
        width: 150px;
        height: 150px;
        border-radius: 5px;
        padding: 5px;
        box-sizing: border-box;
    
}

.card1 button{
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #c3b69f;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.card2 img{

    width: 150px;
    height: 150px;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
}

.card2 button {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #c3b69f;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}