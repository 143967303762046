.profile > div {
  padding: 10px;
  font-size: medium;
  
}
.title {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 20px;
  font-weight: 500;
}

.genderBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
  font-size: small;
}
.gender {
  margin-left: 5px;
  margin-right: 20px;
  width: 50px;
}

.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}
.marriedBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
  font-size: small;
}
.married {
  margin-left: 5px;
  margin-right: 20px;
  font-size: medium;
  width: 50px;
}
.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}
.residentsBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
  font-size: small;
}
.residents {
  margin-left: 5px;
  margin-right: 20px;
  font-size: medium;
  width: 50px;
}
.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}
.employmentBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
}
.employmentStatus {
  margin-left: 5px;
  margin-right: 20px;
  font-size: medium;
  width: 50px;
}
.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}
.kidsBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
  margin-top: 8px;
}
.kids {
  margin-left: 5px;
  margin-right: 12px;
  font-size: medium;
  width: 95px;
}
.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}
.educationBox {
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 10px;
}
.education {
  margin-left: 5px;
  margin-right: 20px;
  font-size: small;
  width: 50px;
  margin-top: 1px;
  font-weight: 470;
}
.vertical-divider {
  height: 20px; /* 根据需要调整高度 */
  width: 1px; /* 竖线的宽度 */
  background-color: darkolivegreen; /* 竖线的颜色，这里是黑色 */
  margin: 0 30px; /* 根据需要调整左右的间距 */
  align-self: center; /* 如果父元素是 flex，这将帮助垂直居中 */
}

.saveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  font-size: medium;
  cursor: pointer;
}
.saveButton:hover > button {
  background-color: #80b197; /* 悬停时的背景颜色 */
  color:bisque; /* 悬停时的文字颜色 */
}

