.navbar {
  position: fixed;
  left: 0;
  bottom: 0px;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgb(201, 191, 167);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile {
  width: 100%;
}

.background-image {
  width: 100%;
  padding: 0px !important;
}

.background-image > img {
  object-fit: cover;
}
